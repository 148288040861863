.navegacion {
    border-bottom: 1px solid #242424;
    background-color: #ffffff;
    margin-bottom: 1em;
}

.navegacion button {
    background-color: transparent;
    border: none;
    color: #242424;
    cursor: pointer;
    min-height: 30px;
    min-width: 30px;
    max-height: 35px;
    max-width: 35px;
    border: 1px solid #242424;
    border-radius: 20%;
    padding: 0;
}

.navegacion button:hover {
    color: #ffffff;
    background-color: #242424;
    border: #ffffff 1px solid;
}