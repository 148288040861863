body {
    font-size: 16px;
}

h1{
    font-size: 1.7rem;
}

h2 {
    font-size: 1.5rem;
}

h3 {
    font-size: 1.3rem;
}

h4 {
    font-size: 1.1rem;
}

h5 {
    font-size: 1rem;
}

.content-text {
    text-align: justify;
}

.content-text a {
    color: #007bff;
    text-decoration: none;
}

.content-text a:hover {
    text-decoration: underline;
}

.content-text img {
    max-width: 100%;
    height: auto;
}

.ck-widget__type-around {
    display: none;
}

.ck-widget__resizer {
    display: none;
}

.file-badge {
    background-color: #ffffff !important;
    border: 1px solid #6c757d;
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
}

.file-badge:hover {
    background-color: #6c757d !important;
    color: #ffffff !important;
}

.file-badge:hover a {
    color: #ffffff !important;
}

.file-badge a {
    font-size: 1.2em;
    color: #6c757d;
    text-decoration: none;
}

.file-badge a:hover {
    color: #ffffff;
}

/* Este tipo de estilo aplica a una imagen redimensionada que se alinea a la izquierda que debe mostrar el texto siguiente a la derecha */
.image-style-align-left {
    float: left !important;
    margin-right: 1rem !important;
    margin-bottom: 1rem !important;
    clear: both !important;
}

.image-style-align-right {
    float: left !important;
    margin-right: 1rem !important;
    margin-bottom: 1rem !important;
    clear: both !important;
}

.float-toast {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 9999;
}

blockquote {
    font-style: italic;
    border-left: 0.5rem solid #6c757d;
    padding-left: 1rem;
}

blockquote a {
    word-break: break-all;
}

@media (max-width: 450px) {
    .file-badge {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        text-align: left !important;
        width: 90%;
    }

    .image-style-align-left, .image-style-align-right {
        float: none !important;
        margin-right: 0 !important;
        margin-bottom: 1rem !important;
        clear: both !important;
        width: 100% !important;
    }
}